// *** slice : “a collection of Redux reducer logic and actions for a single feature in your app.”

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

import { createSlice } from "@reduxjs/toolkit"
import { User, CollectionUser, Collection, Article, Recipe, ArticleRecipe } from "types"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

// Type for our state
interface AppState {
	selectedCollection: Collection | null
	//* forms:
	selectedFormCollection: Collection | null
	selectedFormArticle: Article | null
	selectedFormRecipe: Recipe | null
}

// Initial state
const initialState: AppState = {
	selectedCollection: null,
	//* forms:
	selectedFormCollection: null,
	selectedFormArticle: null,
	selectedFormRecipe: null,
	/*
	selectedFormEntite: null,
	selectedFormAttribut: null,
	selectedFormAdresse: null,
	*/
}

// Actual Slice
export const appSlice = createSlice({
	name: "app",
	initialState,
	reducers: {
		appSetSelectedCollection(state, action) {
			state.selectedCollection = action.payload
		},

		//* CRUD ARTICLE:
		appAddArticleToSelectedCollection(state, action) {
			if (state.selectedCollection) {
				state.selectedCollection.articles = [...state.selectedCollection.articles, action.payload]
			}
		},
		appUpdateArticleInSelectedCollection(state, action) {
			if (state.selectedCollection) {
				state.selectedCollection.articles = state.selectedCollection.articles.map((article) =>
					article.id === action.payload.id ? action.payload : article
				)
			}
		},
		appDeleteArticleInSelectedCollection(state, action) {
			if (state.selectedCollection) {
				state.selectedCollection.articles = state.selectedCollection.articles.filter(
					(article) => article.id !== action.payload.id
				)
			}
		},

		//* CRUD RECIPE:
		appAddRecipeToSelectedCollection(state, action) {
			if (state.selectedCollection) {
				state.selectedCollection.recipes = [...state.selectedCollection.recipes, action.payload]
			}
		},
		appUpdateRecipeInSelectedCollection(state, action) {
			if (state.selectedCollection) {
				state.selectedCollection.recipes = state.selectedCollection.recipes.map((recipe) =>
					recipe.id === action.payload.id ? action.payload : recipe
				)
			}
		},
		appDeleteRecipeInSelectedCollection(state, action) {
			if (state.selectedCollection) {
				state.selectedCollection.recipes = state.selectedCollection.recipes.filter(
					(recipe) => recipe.id !== action.payload.id
				)
			}
		},

		//* forms:
		appSetSelectedFormCollection(state, action) {
			state.selectedFormCollection = action.payload
		},
		appSetSelectedFormArticle(state, action) {
			state.selectedFormArticle = action.payload
		},
		appSetSelectedFormRecipe(state, action) {
			state.selectedFormRecipe = action.payload
		},
	},
})

export const {
	appSetSelectedCollection,

	//* CRUD ARTICLE:
	appAddArticleToSelectedCollection,
	appUpdateArticleInSelectedCollection,
	appDeleteArticleInSelectedCollection,

	//* CRUD RECIPE:
	appAddRecipeToSelectedCollection,
	appUpdateRecipeInSelectedCollection,
	appDeleteRecipeInSelectedCollection,

	//* forms:
	appSetSelectedFormCollection,
	appSetSelectedFormArticle,
	appSetSelectedFormRecipe,
} = appSlice.actions

export default appSlice.reducer
/*
const { appLogoutSuccessX } = appSlice.actions

export const appLogoutSuccess = () => (dispatch: any) => {

	dispatch(appLogoutSuccessX())
}
*/
