// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

import React, { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "store/store"
import { Collection } from "types"
import FormCollection from "features/collections/FormCollection"
import ZLoadingSection from "ui/ZLoadingSection"
import ZErrorSection from "ui/ZErrorSection"
import { useZFetchMyCollections } from "api/api.collections"
import MyCollections from "features/collections/MyCollections"
import { appSetSelectedCollection } from "store/appSlice"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PageHome() {
	const dispatch = useAppDispatch()
	const { selectedFormCollection } = useAppSelector((state) => state.app)
	const { collections, setCollections, isLoading, fetchError } = useZFetchMyCollections()

	useEffect(() => {
		document.title = "SL"
		dispatch(appSetSelectedCollection(null))
	}, [dispatch])

	return (
		<div className="zPage">
			{collections && selectedFormCollection && (
				<FormCollection
					collectionItem={selectedFormCollection}
					addCollections={(collection: Collection) => setCollections([...collections, collection])}
				/>
			)}

			<header className="zPageHeader">
				<img src="/img/app-logo/app-logo-96.png" alt="SL" className="appLogo d-inline-block  m-0" />
				<div className="d-inline-block ms-4 ">
					<h1>SL</h1>
					<h2>Mes collections</h2>
				</div>
			</header>

			<div className="zPageContent row">
				<ZLoadingSection isLoading={isLoading} className="col-12 col-md-6" />
				<ZErrorSection fetchResponseError={fetchError} className="col-12 col-md-6" />

				{collections && !fetchError && <MyCollections collections={collections} />}
				{/*
				<div className="zSection col-12 col-md-6">
					<div className="zSectionInner">
						<h2>About SL</h2>
						<div className="zSectionContent">
							<p>xxxxxxxxxxxxxxxxxxxxxxxx</p>
						</div>
					</div>
					<div className="zSectionInner">
						<h2>Features</h2>
						<div className="zSectionContent">
							<ul>
								<li>xxxxxxxxxxxxx</li>
								<li>xxxxxxxxxxxx</li>
								<li>xxxxxxxxxxxxxxx</li>
							</ul>
						</div>
					</div>
				</div>
			 */}
			</div>
		</div>
	)
}
