// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

import React from "react"
import { Button } from "react-bootstrap"
import { FaEdit } from "react-icons/fa"
import { Article } from "types"
import { useAppDispatch } from "store/store"
import { appSetSelectedFormArticle, appUpdateArticleInSelectedCollection } from "store/appSlice"
import { FaCartShopping } from "react-icons/fa6"
import { FaPlus, FaTimes } from "react-icons/fa"
import { apiSetArticleInCart } from "api"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

type Props = {
	className?: string
	article: Article
	addToRecipe: any
}

export default function ButtonAddArticleToRecipe({ className, article, addToRecipe }: Props) {
	return (
		<Button
			variant={article.inCart ? "warning" : "success"}
			className={"btInCart " + className}
			title={"Edit article: " + article.name}
			onClick={() => addToRecipe()}
		>
			{article.inCart ? <FaTimes /> : <FaPlus />} add to recipe
		</Button>
	)
}
