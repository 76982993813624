// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

import React from "react"
import { Button } from "react-bootstrap"
import { FaPlus } from "react-icons/fa"
import { useAppDispatch, useAppSelector } from "store/store"
import { appSetSelectedFormRecipe } from "store/appSlice"
// import { ... } from "types"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

type Props = {
	className?: string
	name: string
}

export default function ButtonCreateRecipe({ className, name }: Props) {
	const dispatch = useAppDispatch()
	const { selectedCollection } = useAppSelector((state) => state.app)
	function btClick() {
		dispatch(
			appSetSelectedFormRecipe({
				id: 0,
				name: name,
				collection: selectedCollection,
				// articleRecipes: null,
			})
		)
	}
	return (
		<Button className={className} title="Create a new recipe" onClick={() => btClick()}>
			<FaPlus /> Recipe
		</Button>
	)
}
