// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

import zFetcher from "libs/zFetcher"
import { Article } from "types"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export async function apiFetchArticles() {
	return await zFetcher({
		shortUrl: "/articles",
		method: "GET",
	})
}

export async function apiFetchArticleById(articleId: number) {
	return zFetcher({
		shortUrl: "/articles/" + articleId,
		method: "GET",
	})
}

export async function apiCreateArticle(article: Article, collectionId: number) {
	return zFetcher({
		shortUrl: "/articles/new/collection/" + collectionId,
		method: "POST",
		//body: { article },

		body: {
			name: article.name,
			infos: article.infos,
			inCart: article.inCart,
		},
	})
}

export async function apiEditArticle(article: Article) {
	return zFetcher({
		shortUrl: "/articles/" + article.id + "/edit",
		method: "POST",
		// body: { article }
		body: {
			name: article.name,
			infos: article.infos,
			inCart: article.inCart,
		},
	})
}

export async function apiSetArticleInCart(articleId: number, inCart: boolean) {
	return zFetcher({
		shortUrl: "/articles/" + articleId + "/set-in-cart",
		method: "POST",
		// body: { article }
		body: {
			inCart,
		},
	})
}

export async function apiAddArticleToRecipe(articleId: number, recipeId: number) {
	return zFetcher({
		shortUrl: "/recipes/" + recipeId + "/add-article/" + articleId,
		method: "POST",
		// body: { article }
		body: {
			// quantity // TODO
		},
	})
}

export async function apiDeleteArticleById(articleId: number) {
	return zFetcher({
		shortUrl: "/articles/" + articleId + "/delete",
		method: "DELETE",
	})
}
