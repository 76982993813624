// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

import React from "react"
import { Button } from "react-bootstrap"
import { FaEdit } from "react-icons/fa"
import { Article } from "types"
import { useAppDispatch } from "store/store"
import { appSetSelectedFormArticle, appUpdateArticleInSelectedCollection } from "store/appSlice"
import { FaCartShopping } from "react-icons/fa6"
import { FaTimes } from "react-icons/fa"
import { apiSetArticleInCart } from "api"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

type Props = {
	className?: string
	article: Article
}

export default function ButtonAddArticleToCart({ className, article }: Props) {
	const dispatch = useAppDispatch()
	function btClick() {
		apiSetArticleInCart(article.id, !article.inCart).then((response) => {
			if (response.article) {
				dispatch(appUpdateArticleInSelectedCollection(response.article))
				//addArticles(response.article)
				//dispatch(appAddArticleToSelectedCollection(response.article))
				//dispatch(appSetSelectedFormArticle(null))
				//dispatch(appSetSelectedFormArticle(article))
			} else {
				//setFetchError(response)
			}
			//setIsLoading(false)
		})
	}
	return (
		<Button
			variant={article.inCart ? "warning" : "success"}
			className={"btInCart " + className}
			title={"Edit article: " + article.name}
			onClick={() => btClick()}
		>
			{article.inCart ? <FaTimes /> : <FaCartShopping />}
		</Button>
	)
}
