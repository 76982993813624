// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

import React, { useState } from "react"
import { useAppDispatch } from "store/store"
import { useNavigate } from "react-router-dom"
import ZModal from "ui/ZModal"
import FormRecipeInner from "./FormRecipeInner"
import { Recipe } from "types"
import {
	appSetSelectedFormRecipe,
	appAddRecipeToSelectedCollection,
	appDeleteRecipeInSelectedCollection,
	appUpdateRecipeInSelectedCollection,
} from "store/appSlice"
import { apiCreateRecipe, apiEditRecipe, apiDeleteRecipeById } from "api"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function FormRecipe({
	recipeItem,
	addRecipes,
	setRecipe,
}: {
	recipeItem: Recipe
	addRecipes?: any | null
	setRecipe?: any | null
}) {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const [formItem, setFormItem] = useState<Recipe>(recipeItem)
	const [formErrors, setFormErrors] = useState<any>({})
	const [fetchError, setFetchError] = useState<any | null>(null)
	const [isLoading, setIsLoading] = useState(false)

	// ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■

	const checkErrors = () => {
		let errorCount = 0

		// ******************* Check: name
		/*
		if (!formItem.name || formItem.name.length < 2) {
			errorCount++
			setFormErrors({ ...formErrors, name: "Le name doit faire au moins 2 characteres." })
		}
		*/

		return errorCount
	}

	// ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■

	const btValidateClick = async () => {
		setFetchError(null)
		setFormErrors({})
		if (checkErrors() > 0) return
		if (recipeItem.id === 0) {
			// *** CREATE Recipe :
			if (!formItem.collection) {
				console.error("collection is null - SHOULDNT HAPPEN")
				return
			}
			apiCreateRecipe(formItem, formItem.collection.id).then((response) => {
				if (response.recipe) {
					//addRecipes(response.recipe)
					dispatch(appAddRecipeToSelectedCollection(response.recipe))
					dispatch(appSetSelectedFormRecipe(null))
				} else {
					setFetchError(response)
				}
				setIsLoading(false)
			})
		} else {
			// *** EDIT Recipe :
			apiEditRecipe(formItem).then((response) => {
				if (response.recipe) {
					//setRecipe(response.recipe)
					dispatch(appUpdateRecipeInSelectedCollection(response.recipe))
					dispatch(appSetSelectedFormRecipe(null))
				} else {
					setFetchError(response)
				}
				setIsLoading(false)
			})
		}
	}

	const btDeleteClick = async () => {
		// *** DELETE Recipe :
		if (!window.confirm("Do you really want to delete recipe " + recipeItem.name + " ?")) return
		const tempId = recipeItem.id
		apiDeleteRecipeById(recipeItem.id).then((response) => {
			if (response.success) {
				dispatch(appDeleteRecipeInSelectedCollection({ id: tempId }))
				dispatch(appSetSelectedFormRecipe(null))
				//navigate("/")
			} else {
				setFetchError(response)
			}
			setIsLoading(false)
		})
	}

	// ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■

	return (
		<ZModal styles={null} closeForm={() => dispatch(appSetSelectedFormRecipe(null))} className="zFormInner">
			<FormRecipeInner
				formItem={formItem}
				formErrors={formErrors}
				setFormItem={setFormItem}
				setFormErrors={setFormErrors}
				isLoading={isLoading}
				fetchError={fetchError}
				btValidateClick={btValidateClick}
				btDeleteClick={btDeleteClick}
			/>
		</ZModal>
	)
}
