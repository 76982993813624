// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

import React from "react"
import { Button } from "react-bootstrap"
import { FaPlus } from "react-icons/fa"
import { useAppDispatch } from "store/store"
import { appSetSelectedFormCollection } from "store/appSlice"
// import { ... } from "types"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

type Props = {
	className?: string
}

export default function ButtonCreateCollection({ className }: Props) {
	const dispatch = useAppDispatch()
	function btClick() {
		dispatch(
			appSetSelectedFormCollection({
				id: 0,
				name: "",
				// listeUsers: null,
				// articles: null,
				// recipes: null,
			})
		)
	}
	return (
		<Button className={className} title="Create a new liste" onClick={() => btClick()}>
			<FaPlus /> Collection
		</Button>
	)
}
