// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useZFetchCollectionById } from "api"
import { useAppDispatch, useAppSelector } from "store/store"
import FormCollection from "features/collections/FormCollection"
import PageCollectionHeader from "features/collections/PageCollectionHeader"
import ZErrorSection from "ui/ZErrorSection"
import ZLoadingSection from "ui/ZLoadingSection"
import { appSetSelectedCollection } from "store/appSlice"
import ButtonEditCollection from "features/collections/ButtonEditCollection"
import ArticleItem from "features/articles/ArticleItem"
import ArticlesSearch from "features/collections/ArticlesSearch"
import { Article } from "types"
import ButtonCreateArticle from "features/articles/ButtonCreateArticle"
import FormArticle from "features/articles/FormArticle"
/*
import Uml from "features/uml/Uml"
import CollectionTabsHeader from "features/collections/CollectionTabsHeader"
import CollectionInfos from "features/collections/CollectionInfos"
import Generate from "features/generate/Generate"
import { Entite } from "types"
import FixtureMaker from "features/fixtures/FixtureMaker"
*/
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PageCollectionCart() {
	const collectionId = Number(useParams().id) || 0
	const dispatch = useAppDispatch()
	const { selectedCollection, selectedFormCollection, selectedFormArticle } = useAppSelector((state) => state.app)
	const { collection, setCollection, isLoading, fetchError } = useZFetchCollectionById(collectionId)
	const [searchedText, setSearchedText] = useState("")

	useEffect(() => {
		dispatch(appSetSelectedCollection(collection))
	}, [collection, dispatch])

	function simplifyText(str: string) {
		str = str.toLowerCase()

		let accent = [
			/[\300-\306]/g,
			/[\340-\346]/g, // A, a
			/[\310-\313]/g,
			/[\350-\353]/g, // E, e
			/[\314-\317]/g,
			/[\354-\357]/g, // I, i
			/[\322-\330]/g,
			/[\362-\370]/g, // O, o
			/[\331-\334]/g,
			/[\371-\374]/g, // U, u
			/[\321]/g,
			/[\361]/g, // N, n
			/[\307]/g,
			/[\347]/g, // C, c
		]
		let noaccent = ["A", "a", "E", "e", "I", "i", "O", "o", "U", "u", "N", "n", "C", "c"]

		for (var i = 0; i < accent.length; i++) {
			str = str.replace(accent[i], noaccent[i])
		}

		return str
	}

	function articleMatchesSearch(article: Article, searchedText: string) {
		return simplifyText(article.name).includes(simplifyText(searchedText))
	}

	const filteredArticles = selectedCollection?.articles.filter((article) =>
		searchedText === "" ? article.inCart : articleMatchesSearch(article, searchedText)
	)

	return (
		<div className="zPage">
			{selectedFormCollection && (
				<FormCollection collectionItem={selectedFormCollection} setCollection={setCollection} />
			)}
			{selectedFormArticle && <FormArticle articleItem={selectedFormArticle} />}

			<div className="zPageContent row">
				<ZLoadingSection isLoading={isLoading} className="col-12" />
				<ZErrorSection fetchResponseError={fetchError} className="col-12" />

				<PageCollectionHeader mode="CART" />
				{selectedCollection && (
					<>
						<div className="zSection col-12">
							<div className="zSectionInner">
								<h3>Cart</h3>

								<ArticlesSearch searchedText={searchedText} setSearchedText={setSearchedText} />

								<div>
									{searchedText !== "" && <ButtonCreateArticle name={searchedText} />}
									{filteredArticles?.length} article
									{filteredArticles?.length ? "s" : ""}{" "}
									{searchedText === "" ? "dans le panier" : "trouvees"}:
								</div>
								<div>
									{filteredArticles?.map((article) => (
										<ArticleItem
											key={"k-article-" + article.id}
											article={article}
											addToRecipe={null}
										/>
									))}
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	)
}
