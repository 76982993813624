// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

import React from "react"
import { Link } from "react-router-dom"
import { /*FaCollectionDiagram */ FaTimesCircle } from "react-icons/fa"
import { Button } from "react-bootstrap"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

type Props = {
	searchedText: string
	setSearchedText: any
}

export default function ArticlesSearch({ searchedText, setSearchedText }: Props) {
	return (
		<div className="row col-12 sticky-top stickySearch mb-2">
			<input
				key="inputSearchKey"
				className="col-11"
				value={searchedText}
				name="search"
				onChange={(event) => setSearchedText(event.target.value)}
				placeholder="Rechercher un article (ou une recette ??)"
				//autoFocus={searchedText!=="" ? "true" : "false"}
			/>

			<Button className="col-1 btn-secondary btn-sm" onClick={() => setSearchedText("")}>
				<FaTimesCircle />
			</Button>
		</div>
	)
}
