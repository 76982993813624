// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

import React from "react"
import { Collection } from "types"
import CollectionLink from "features/collections/CollectionLink"
import ButtonCreateCollection from "features/collections/ButtonCreateCollection"
import ButtonFixtureCollection from "./ButtonFixtureCollection"
import { Link } from "react-router-dom"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function MyCollections({ collections }: { collections: Collection[] }) {
	return (
		<div className="zSection col-12 col-md-6">
			<div className="zSectionInner">
				<h2>
					Mes collections <ButtonCreateCollection className="btn-sm float-end" />
				</h2>
				<div className="zSectionContent">
					<ul>
						{collections &&
							collections.map((collection: Collection) => (
								<li key={collection.id}>
									<Link
										to={"/collections/" + collection.collection_id + "/cart"}
										title="Click to see collection."
									>
										{collection.name + " "}
									</Link>
								</li>
							))}
					</ul>
					<div className="mt-5">
						<div>
							<b>Fixture:</b>
						</div>
						<ButtonFixtureCollection />
					</div>
				</div>
			</div>
		</div>
	)
}
