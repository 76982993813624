// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

import React from "react"
import { Button } from "react-bootstrap"
import { FaEdit } from "react-icons/fa"
import { Recipe } from "types"
import { useAppDispatch } from "store/store"
import { appSetSelectedFormRecipe } from "store/appSlice"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

type Props = {
	className?: string
	recipe: Recipe
}

export default function ButtonEditRecipe({ className, recipe }: Props) {
	const dispatch = useAppDispatch()
	function btClick() {
		dispatch(appSetSelectedFormRecipe(recipe))
	}
	return (
		<Button variant="warning" className={className} title={"Edit recipe: " + recipe.name} onClick={() => btClick()}>
			<FaEdit />
		</Button>
	)
}
