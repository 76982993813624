// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useZFetchCollectionById } from "api"
import { useAppDispatch, useAppSelector } from "store/store"
import FormCollection from "features/collections/FormCollection"
import PageCollectionHeader from "features/collections/PageCollectionHeader"
import ZErrorSection from "ui/ZErrorSection"
import ZLoadingSection from "ui/ZLoadingSection"
import { appSetSelectedCollection } from "store/appSlice"
import ButtonEditCollection from "features/collections/ButtonEditCollection"
import ButtonCreateArticle from "features/articles/ButtonCreateArticle"
import FormArticle from "features/articles/FormArticle"
import ArticleItem from "features/articles/ArticleItem"
/*
import Uml from "features/uml/Uml"
import CollectionTabsHeader from "features/collections/CollectionTabsHeader"
import CollectionInfos from "features/collections/CollectionInfos"
import Generate from "features/generate/Generate"
import { Entite } from "types"
import FixtureMaker from "features/fixtures/FixtureMaker"
*/
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PageCollectionArticles() {
	const collectionId = Number(useParams().id) || 0
	const dispatch = useAppDispatch()
	const { selectedCollection, selectedFormCollection, selectedFormArticle } = useAppSelector((state) => state.app)
	const { collection, setCollection, isLoading, fetchError } = useZFetchCollectionById(collectionId)

	useEffect(() => {
		dispatch(appSetSelectedCollection(collection))
	}, [collection, dispatch])

	return (
		<div className="zPage">
			{selectedFormCollection && (
				<FormCollection collectionItem={selectedFormCollection} setCollection={setCollection} />
			)}
			{selectedFormArticle && <FormArticle articleItem={selectedFormArticle} />}
			<div className="zPageContent row">
				<ZLoadingSection isLoading={isLoading} className="col-12" />
				<ZErrorSection fetchResponseError={fetchError} className="col-12" />

				<PageCollectionHeader mode="ARTICLES" />
				<ButtonCreateArticle name="" />
				{selectedCollection && (
					<>
						<div className="zSection col-12">
							<div className="zSectionInner">
								<h3>Articles</h3>
								<div>
									{selectedCollection.articles.map((article) => (
										<ArticleItem
											key={"k-article-" + article.id}
											article={article}
											addToRecipe={null}
										/>
									))}
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	)
}
