// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

import React from "react"
import { Button } from "react-bootstrap"
import { FaEdit } from "react-icons/fa"
import { Article } from "types"
import { useAppDispatch } from "store/store"
import { appSetSelectedFormArticle } from "store/appSlice"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

type Props = {
	className?: string
	article: Article
}

export default function ButtonEditArticle({ className, article }: Props) {
	const dispatch = useAppDispatch()
	function btClick() {
		dispatch(appSetSelectedFormArticle(article))
	}
	return (
		<Button
			variant="warning"
			className={"btEditArticle " + className}
			title={"Edit article: " + article.name}
			onClick={() => btClick()}
		>
			<FaEdit />
		</Button>
	)
}
