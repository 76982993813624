// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { apiAddArticleToRecipe, useZFetchRecipeById } from "api"
import { useAppDispatch, useAppSelector } from "store/store"
import ZErrorSection from "ui/ZErrorSection"
import ZLoadingSection from "ui/ZLoadingSection"
import { appSetSelectedCollection } from "store/appSlice"
import ArticleItem from "features/articles/ArticleItem"
import { Button } from "react-bootstrap"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PageRecipe() {
	const recipeId = Number(useParams().recipeId) || 0
	const dispatch = useAppDispatch()
	const { selectedCollection, selectedFormRecipe } = useAppSelector((state) => state.app)
	const { recipe, isLoading, fetchError } = useZFetchRecipeById(recipeId)
	const [showAddArticles, setShowAddArticles] = useState(false)

	useEffect(() => {
		dispatch(appSetSelectedCollection(recipe?.collection || null))
	}, [recipe, dispatch])

	const addArticleToRecipe = (articleId: number) => {
		console.debug("add Article #" + articleId + " To Recipe ; :", recipe?.name)

		apiAddArticleToRecipe(articleId, recipeId).then((response) => {
			if (response.success) {
				//dispatch(appUpdateArticleInSelectedCollection(response.article))
				//addArticles(response.article)
				//dispatch(appAddArticleToSelectedCollection(response.article))
				//dispatch(appSetSelectedFormArticle(null))
				//dispatch(appSetSelectedFormArticle(article))
			} else {
				//setFetchError(response)
			}
			//setIsLoading(false)
		})
	}

	return (
		<div className="zPage">
			{/*
			{selectedFormRecipe && <FormRecipe recipeItem={selectedFormRecipe} />}
			*/}

			<div className="zPageContent row">
				<ZLoadingSection isLoading={isLoading} className="col-12" />
				<ZErrorSection fetchResponseError={fetchError} className="col-12" />

				<header className="zPageHeader row">
					<h1>
						<small>{recipe?.collection?.name}: Recette </small>
						<b>{recipe?.name}</b>
						{/*
						{selectedCollection && (
							<ButtonEditCollection className="btn-sm float-end" collection={selectedCollection} />
						)}
						*/}
					</h1>
				</header>
				{recipe && (
					<>
						<div className="zSection col-12">
							<div className="zSectionInner">
								<Button
									onClick={() => {
										setShowAddArticles(!showAddArticles)
									}}
								>
									Ajouter des articles a la recette
								</Button>
								{/*
							<ArticlesSearch searchedText={searchedText} setSearchedText={setSearchedText} />

							<div>
								{searchedText !== "" && <ButtonCreateArticle name={searchedText} />}
								{filteredArticles?.length} article
								{filteredArticles?.length ? "s" : ""}{" "}
								{searchedText === "" ? "dans le panier" : "trouvees"}:
							</div>
							*/}
								{showAddArticles && (
									<div>
										--{selectedCollection?.name}--
										{selectedCollection?.articles?.map((article) => (
											<ArticleItem
												key={"k-article-" + article.id}
												article={article}
												addToRecipe={() => addArticleToRecipe(article.id)}
											/>
										))}
									</div>
								)}
							</div>
						</div>
						<div className="zSection col-12">
							<div className="zSectionInner">
								<h3>Articles de la recette</h3>

								{recipe?.articleRecipes?.map((artRecipe) => (
									<div key={"k-article-" + artRecipe.id}>
										{
											selectedCollection?.articles?.filter(
												(article) => article.id === artRecipe.article.id
											)[0]?.name
										}
									</div>
								))}
							</div>
						</div>

						<div className="zSection col-12">
							<div className="zSectionInner">
								<h3>
									<small>Recipe:</small> {recipe.name}
								</h3>

								<ul>
									<li>
										ID de recette (Primary Key) : Un identifiant unique pour chaque recette. Cela
										peut être un nombre entier auto-incrémenté.
									</li>
									<li>Nom de la recette : Le nom de la recette.</li>
									<li>Description : Une brève description de la recette.</li>
									<li>
										Instructions de préparation : Les étapes détaillées pour préparer la recette.
									</li>
									<li>Temps de préparation : Le temps nécessaire pour préparer la recette.</li>
									<li>Temps de cuisson : Le temps nécessaire pour cuire la recette.</li>
									<li>Nombre de portions : Le nombre de portions que la recette produit.</li>
									<li>
										Difficulté : Un indicateur de la difficulté de préparation de la recette (par
										exemple, facile, moyen, difficile).
									</li>
									<li>
										Catégorie de cuisine : La catégorie à laquelle la recette appartient (par
										exemple, italienne, française, végétarienne, etc.).
									</li>
									<li>
										Liste des ingrédients : Une liste des ingrédients nécessaires pour préparer la
										recette.
									</li>
									<li>
										Image de la recette : Une URL ou un chemin vers une image représentant la
										recette.
									</li>
									<li>
										Date de création : La date à laquelle la recette a été ajoutée à la base de
										données.
									</li>
									<li>Auteur : L'auteur de la recette.</li>
									<li>Note moyenne : La note moyenne donnée par les utilisateurs à la recette.</li>
									<li>Nombre de vues : Le nombre de fois que la recette a été consultée.</li>
									<li>
										Tags ou mots-clés : Des mots-clés qui décrivent la recette et facilitent la
										recherche.
									</li>
									<li>
										Ingrédients et allergènes : Des informations sur les allergènes présents dans la
										recette.
									</li>
									<li>
										Commentaires : Une section pour stocker les commentaires des utilisateurs sur la
										recette.
									</li>
								</ul>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	)
}
