// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

import React, { useState } from "react"
import { Button } from "react-bootstrap"
import { FaPlus } from "react-icons/fa"
import { useAppDispatch } from "store/store"
import { appSetSelectedFormCollection } from "store/appSlice"
import { apiFixturesCollection } from "api"
// import { ... } from "types"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

type Props = {
	className?: string
}

export default function ButtonFixtureCollection({ className }: Props) {
	const [fetchError, setFetchError] = useState<any | null>(null)
	const [isLoading, setIsLoading] = useState(false)
	const dispatch = useAppDispatch()

	function btClick() {
		if (!window.confirm("Do you really want to add this fixture ?")) return

		setIsLoading(true)

		setFetchError(null)

		apiFixturesCollection().then((response) => {
			if (response.success) {
				window.location.reload() // !!!!!!!!!!!!!!
			} else if (response.error) {
				if (response.error === "XXXXXXX") setFetchError("Xxxxxx")
				else if (response.error === "YYYYYYY") setFetchError("yyyyyyyyy")
				else {
					console.log("response: ", response)
					setFetchError("Erreur Inconnue")
				}
			} else {
				console.log("response: ", response)
				setFetchError("Erreur Inconnue")
			}
			setIsLoading(false)
		})
	}

	if (fetchError) return <div className="alert alert-danger">{fetchError}</div>

	return (
		<Button className={className} title="Create a new liste" onClick={() => btClick()} disabled={isLoading}>
			{!isLoading ? (
				<>
					<FaPlus /> Fixture Collection
				</>
			) : (
				<>...</>
			)}
		</Button>
	)
}
