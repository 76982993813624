// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

import React, { useState, useEffect } from "react"
import zFetcher from "libs/zFetcher"
import { Collection } from "types"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

/*
 * *****************************************************************************
 *
 *	USAGE:
 *	const { collections, setCollections, isLoading, fetchError } = useZFetchMyCollections()
 *
 */
export const useZFetchMyCollections = () => {
	const [collections, setCollections] = useState<Collection[] | null>(null)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [fetchError, setFetchError] = useState<any | null>(null)

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true)
			await zFetcher({
				shortUrl: "/collections/my",
				method: "GET",
				// body: {},
				//publicAccess: false
			}).then((rep) => {
				if (rep.collections) setCollections(rep.collections)
				else setFetchError(rep)
				setIsLoading(false)
			})
		}
		fetchData()
	}, [])
	return { collections, setCollections, isLoading, fetchError }
}

/*
 * *****************************************************************************
 *
 *	USAGE:
 *	const { collection, setCollection, isLoading, fetchError } = useZFetchCollectionById(collectionId)
 *
 */
export const useZFetchCollectionById = (collectionId: number) => {
	const [collection, setCollection] = useState<Collection | null>(null)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [fetchError, setFetchError] = useState<any | null>(null)

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true)
			await zFetcher({
				shortUrl: "/collections/" + collectionId,
				method: "GET",
				// body: {},
				//publicAccess: false
			}).then((rep) => {
				if (rep.collection) setCollection(rep.collection)
				else setFetchError(rep)
				setIsLoading(false)
			})
		}
		fetchData()
	}, [collectionId])
	return { collection, setCollection, isLoading, fetchError }
}

/*
export async function apiFetchCollectionById(collectionId: number) {
	return zFetcher({
		shortUrl: "/collections/" + collectionId,
		method: "GET",
	})
}
*/

export async function apiFixturesCollection() {
	return await zFetcher({
		shortUrl: "/fixtures/collection",
		method: "GET",
	})
}

export async function apiFetchCollections() {
	return await zFetcher({
		shortUrl: "/collections",
		method: "GET",
	})
}

export async function apiCreateCollection(collection: Collection) {
	return zFetcher({
		shortUrl: "/collections/new",
		method: "POST",
		// body: { collection }
		body: {
			name: collection.name,
			// collectionUsers: collection.collectionUsers,
			// articles: collection.articles,
			// recipes: collection.recipes,
		},
	})
}

export async function apiEditCollection(collection: Collection) {
	return zFetcher({
		shortUrl: "/collections/" + collection.id + "/edit",
		method: "POST",
		// body: { collection }
		body: {
			name: collection.name,
			// collectionUsers: collection.collectionUsers,
			// articles: collection.articles,
			// recipes: collection.recipes,
		},
	})
}

export async function apiDeleteCollectionById(collectionId: number) {
	return zFetcher({
		shortUrl: "/collections/" + collectionId + "/delete",
		method: "DELETE",
	})
}
