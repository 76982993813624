// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

import React, { useEffect, useState } from "react"
import { Recipe } from "types"
import ButtonEditRecipe from "./ButtonEditRecipe"
import { Link } from "react-router-dom"

/*
import Uml from "features/uml/Uml"
import CollectionTabsHeader from "features/collections/CollectionTabsHeader"
import CollectionInfos from "features/collections/CollectionInfos"
import Generate from "features/generate/Generate"
import { Entite } from "types"
import FixtureMaker from "features/fixtures/FixtureMaker"
*/
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function RecipeItem({ collectionId, recipe }: { collectionId: number; recipe: Recipe }) {
	return (
		<div className={"recipeItem "}>
			<ButtonEditRecipe recipe={recipe} />
			<Link className="center bg-info ms-3" to={`/collections/${collectionId}/recipes/${recipe.id}`}>
				<div className="name">{recipe.name}</div>
				{/*
				{recipe.infos && <div className="infos">{recipe.infos}</div>}
				*/}
			</Link>
		</div>
	)
}
