// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

import React from "react"
import { Link } from "react-router-dom"
import { Nav /*, NavDropdown */ } from "react-bootstrap"
import NavBarDropdownConnectedUser from "./NavBarDropdownConnectedUser"
import { /* FaBan, FaUsers, */ FaFolder, FaQuestion } from "react-icons/fa"
import { useAppSelector } from "store/store"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function NavBarPrivateItems() {
	const { selectedCollection } = useAppSelector((state) => state.app)
	/*
	function NavBarDropdownAdmin() {
		return (
			<NavDropdown
				title={
					<>
						<FaBan /> Dev
					</>
				}
				id="basic-nav-dropdown-dev"
			>
				{/*
				<NavDropdown.Item as={Link} to="/admin/users">
					<FaUsers /> Admin: Users
				</NavDropdown.Item>
				* /}
				<NavDropdown.Item as={Link} to="/dev">
					<FaBan /> Dev
				</NavDropdown.Item>
			</NavDropdown>
		)
	}
	*/

	return (
		<>
			<Nav.Link as={Link} to="/">
				<FaFolder /> Mes Collections
			</Nav.Link>
			{selectedCollection && (
				<>
					<Nav.Link disabled className="text-white">
						{selectedCollection.name} :
					</Nav.Link>
					<Nav.Link as={Link} to={`/collections/${selectedCollection.id}/cart`}>
						<FaQuestion /> Cart
					</Nav.Link>
					<Nav.Link as={Link} to={`/collections/${selectedCollection.id}/articles`}>
						<FaQuestion /> Articles
					</Nav.Link>
					<Nav.Link as={Link} to={`/collections/${selectedCollection.id}/recipes`}>
						<FaQuestion /> Recipes
					</Nav.Link>
				</>
			)}
			{/*
			<NavBarDropdownAdmin />
			*/}
			<NavBarDropdownConnectedUser />
		</>
	)
}
