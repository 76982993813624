// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

import { useEffect, useState } from "react"
import zFetcher from "libs/zFetcher"
import { Recipe } from "types"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export async function apiFetchRecipes() {
	return await zFetcher({
		shortUrl: "/recipes",
		method: "GET",
	})
}

/*
 * *****************************************************************************
 *
 *	USAGE:
 *	const { recipe, setRecipe, isLoading, fetchError } = useZFetchRecipeById(collectionId)
 *
 */
export const useZFetchRecipeById = (recipeId: number) => {
	const [recipe, setRecipe] = useState<Recipe | null>(null)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [fetchError, setFetchError] = useState<any | null>(null)

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true)
			await zFetcher({
				shortUrl: "/recipes/" + recipeId,
				method: "GET",
				// body: {},
				//publicAccess: false
			}).then((rep) => {
				if (rep.recipe) setRecipe(rep.recipe)
				else setFetchError(rep)
				setIsLoading(false)
			})
		}
		fetchData()
	}, [recipeId])
	return { recipe, setRecipe, isLoading, fetchError }
}

export async function apiFetchRecipeById(recipeId: number) {
	return zFetcher({
		shortUrl: "/recipes/" + recipeId,
		method: "GET",
	})
}

export async function apiCreateRecipe(recipe: Recipe, collectionId: number) {
	return zFetcher({
		shortUrl: "/recipes/new/collection/" + collectionId,
		method: "POST",
		// body: { recipe }
		body: {
			name: recipe.name,
			// articleRecipes: recipe.articleRecipes,
		},
	})
}

export async function apiEditRecipe(recipe: Recipe) {
	return zFetcher({
		shortUrl: "/recipes/" + recipe.id + "/edit",
		method: "POST",
		// body: { recipe }
		body: {
			name: recipe.name,
			// collection: recipe.collection,
			// articleRecipes: recipe.articleRecipes,
		},
	})
}

export async function apiDeleteRecipeById(recipeId: number) {
	return zFetcher({
		shortUrl: "/recipes/" + recipeId + "/delete",
		method: "DELETE",
	})
}
