// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

import React from "react"
import { Collection } from "types"
import ButtonEditCollection from "features/collections/ButtonEditCollection"
import { FaPersonDigging } from "react-icons/fa6"
import { useAppSelector } from "store/store"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

type Props = {
	mode: "CART" | "ARTICLES" | "RECIPES"
}

export default function PageCollectionHeader({ mode }: Props) {
	const { selectedCollection } = useAppSelector((state) => state.app)

	return (
		<header className="zPageHeader row">
			<h1>
				<small>{selectedCollection && selectedCollection.name}: </small>
				<b>{mode === "CART" && "Cart"}</b>
				<b>{mode === "ARTICLES" && "Articles"}</b>
				<b>{mode === "RECIPES" && "Recipes"}</b>
				{selectedCollection && (
					<ButtonEditCollection className="btn-sm float-end" collection={selectedCollection} />
				)}
			</h1>

			<div className=" col-12 col-md-6">
				{/*
				{collection?.isWip && (
					<div className="text-danger">
						<FaPersonDigging /> Work In Progress
					</div>
				)}
				{collection?.description && (
					<div title="Description du projet">
						Description: <b>{collection.description}</b>
					</div>
				)}
				{collection?.infos && (
					<div title="Infos du projet">
						Infos: <b>{collection.infos}</b>
					</div>
				)}
				*/}
			</div>
		</header>
	)
}
