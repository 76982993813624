// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

import React, { useEffect, useState } from "react"
import { Article } from "types"
import ButtonEditArticle from "./ButtonEditArticle"
import ButtonAddArticleToCart from "./ButtonAddArticleToCart"
import ButtonAddArticleToRecipe from "./ButtonAddArticleToRecipe"

/*
import Uml from "features/uml/Uml"
import CollectionTabsHeader from "features/collections/CollectionTabsHeader"
import CollectionInfos from "features/collections/CollectionInfos"
import Generate from "features/generate/Generate"
import { Entite } from "types"
import FixtureMaker from "features/fixtures/FixtureMaker"
*/
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function ArticleItem({ article, addToRecipe }: { article: Article; addToRecipe: any | null }) {
	return (
		<div className={"articleItem " + (article.inCart ? "inCart" : "")}>
			<ButtonEditArticle article={article} />
			<div className="center">
				<div className="name">{article.name}</div>
				{article.infos && <div className="infos">{article.infos}</div>}
			</div>
			{addToRecipe !== null ? (
				<ButtonAddArticleToRecipe article={article} className="" addToRecipe={addToRecipe} />
			) : (
				<ButtonAddArticleToCart article={article} className="" />
			)}
		</div>
	)
}
